import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

const SocialPage = () => (
    <Layout>
        <Seo title="Social"/>
        <p>Here you can find ways to connect with other Kickass Presbyterians.
            (Or maybe other less kickass denominations).</p>
        <a href="https://www.reddit.com/r/KickassPresbyterian/" target="_blank">
            Kickass Presbyterian Reddit
        </a>
    </Layout>
);

export default SocialPage;
